@import "https://fonts.googleapis.com/css2?family=Raleway:wght@100;400&family=Roboto:wght@400&family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&display=swap";
html {
  font-size: min(max(3vw, 12px), 18px);
}

body {
  max-width: 600px;
  color: #333;
  margin: 0 auto auto;
  font-family: Roboto, sans-serif;
}

main {
  margin: 0 5px;
}

.current-conditions {
  justify-content: space-between;
  align-items: stretch;
  font-family: Raleway, sans-serif;
  display: flex;
}

.current-conditions .temp {
  font-size: 6.5rem;
  font-weight: 100;
}

.current-conditions .metadata {
  text-align: right;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
}

canvas {
  width: calc(100% - 2px);
  border: 1px solid rgba(0, 0, 0, .075);
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

th, td {
  border-bottom: 1px solid rgba(0, 0, 0, .075);
  padding: 0 4px;
}

col.temp {
  border-left: 1px solid rgba(0, 0, 0, .075);
}

span.temp-label {
  color: #000;
  background: #ddd;
  border-radius: 3px;
  padding: 3px;
  display: inline-block;
  position: relative;
}

span.temp-label.high {
  color: #fff;
  background: #af2900;
}

span.temp-label.low {
  color: #fff;
  background: #0045db;
}

footer {
  text-align: center;
  font-style: italic;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 100, "GRAD" 0, "opsz" 48;
}

/*# sourceMappingURL=index.435748fa.css.map */

/**
 * @license
 * Copyright 2020 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400&family=Roboto:wght@400&family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&display=swap');

html {
  font-size: min(max(3vw, 12px), calc(600px / 100 * 3));
}

body {
  max-width: 600px;
  margin: auto;
  font-family: Roboto, sans-serif;
  color: #333;
  margin-top: 0;
}

main {
  margin: 0 5px;
}

.current-conditions {
  font-family: Raleway, sans-serif;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.current-conditions .temp {
  font-weight: 100;
  font-size: 6.5rem;
}

.current-conditions .metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: right;
}

canvas {
  border: solid rgba(0, 0, 0, 0.075) 1px;
  width: calc(100% - 2px);
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

th,
td {
  padding: 0 4px;
  border-bottom: solid rgba(0, 0, 0, 0.075) 1px;
}

col.temp {
  border-left: solid rgba(0, 0, 0, 0.075) 1px;
}

span.temp-label {
  display: inline-block;
  background: #ddd;
  color: black;
  padding: 3px;
  position: relative;
  border-radius: 3px;
}

span.temp-label.high {
  background: rgb(175, 41, 0);
  color: white;
}
span.temp-label.low {
  background: rgb(0, 69, 219);
  color: white;
}

footer {
  font-style: italic;
  text-align: center;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 48
}